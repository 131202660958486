import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


if (process.env.REACT_APP_ENV_MODE === 'production') {
  console.log('Production mode enabled');
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn = () => { }
}