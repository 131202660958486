import React, { useState, useEffect } from "react";
import { useReducer } from 'react';
import * as con from '../../repository/api_calls';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';

// function reducer(state, action) {
//     if (action.type === 'set_value') {
//         state[action.key] = action.value;
//         return {

//             "title": state.title,
//             "date": state.date,
//             "image": state.image,
//             "id": state.id
//         };
//     }
// }

function reducer(state, action) {
    switch (action.type) {
        case 'set_value':
            return {
                ...state,
                [action.key]: action.value
            };
        default:
            return state;
    }
}

// const handleFileInputChange = (e, dispatch) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     const reader = new FileReader();
//     reader.onload = (event) => {
//         dispatch({ type: 'set_base64', value: event.target.result });
//     };
//     reader.readAsDataURL(file);
// };


// function reducer(state, action) {
//     if (action.type === 'set_value') {
//         return {
//             ...state,
//             [action.key]: action.value
//         };
//     }
//     if (action.type === 'set_base64') {
//         return {
//             ...state,
//             image: action.value
//         };
//     }
//     return state;
// }




const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const Event = () => {

    // ---------------------------- popup form api ---------------

    const userRoleId = localStorage.getItem('role_id');
    const staff_category_name = localStorage.getItem('staff_category_name');


    const [state, dispatch] = useReducer(reducer, {
        title: '',
        date: '',
        image: null,
        id: ''
    });

    // const submitStationary = (event) => {
    //     event.preventDefault();
    //     addOthers(state);
    // }

    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = (error) => reject(error);
    //     });
    // };
    // const submitStationary = async (event) => {
    //     event.preventDefault();

    //     const imageBase64 = await convertToBase64(state.image);

    //     const payload = {
    //         title: state.title,
    //         date: state.date,
    //         image: imageBase64,
    //         id: state.id,
    //     };

    //     if (state.id) {
    //         addOthers(payload, 'edit');
    //     } else {
    //         addOthers(payload, 'add');
    //     }
    // };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!(file instanceof Blob)) {
                return reject(new TypeError("The provided file is not a Blob or File."));
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const submitStationary = async (event) => {
        event.preventDefault();

        try {
            const imageBase64 = await convertToBase64(state.image);

            const payload = {
                title: state.title,
                date: state.date,
                image: imageBase64,
                id: state.id,
            };
            console.log(state.image)
            if (state.id) {
                addOthers(state, 'edit');
            } else {
                addOthers(state, 'add');
            }

        } catch (error) {
            console.error("Failed to convert image to Base64:", error);
        }
    };



    async function addOthers(stationary, type) {

        console.log(stationary)
        console.log(stationary.id)

        const formData = new FormData();

        formData.append('title', stationary.title);
        formData.append('date', stationary.date);
        formData.append('image', stationary.image);
        formData.append('id', stationary.id);

        for (const [key, value] of formData.entries()) {
            console.log(key, value);
        }


        // Send API request
        if (type == 'add') {
            const response = await con.CreateEvent(formData);

            if (response['data'] === true) {
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            } else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
        else {
            const response = await con.UpdateEvent(formData);

            if (response['data'] === true) {

                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
    }




    const [editmode, setEditmode] = useState(false);

    // ----------- Change Table Heading and Button Dynamically -------------------

    const [heading, setHeading] = useState('Default Heading');
    const [button, setButton] = useState('Default Heading');
    const [addPharm, setaddPharm] = useState(false)
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = (newHeading, newButton) => {
        setHeading(newHeading);
        setButton(newButton)
        setEditmode(true)
        setDropdownOpen(!isDropdownOpen);
        setaddPharm(!addPharm);

        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'title' })
        dispatch({ type: 'set_value', value: '', key: 'image' })
        dispatch({ type: 'set_value', value: '', key: 'date' })

    };


    // ---------------- for edit function -------

    function handleForEditButtonClick(newHeading, newButton, PharExpan) {
        setHeading(newHeading);
        setButton(newButton)
        setDropdownOpen(true);
        setEditmode(false)
        console.log(PharExpan)

        dispatch({ type: 'set_value', value: PharExpan.id, key: 'id' })
        dispatch({ type: 'set_value', value: PharExpan.title, key: 'title' })
        dispatch({ type: 'set_value', value: PharExpan.image, key: 'image' })
        dispatch({ type: 'set_value', value: PharExpan.date, key: 'date' })


    };



    // -------------------------------  Stationery or others  list API -----------

    const [stationery, setStationery] = useState([])


    useEffect(() => {
        getEvent();
    }, [])

    async function getEvent() {
        const response = await con.GetEvent();
        console.log(response['data'])
        if (response['status'] === true) {
            setStationery(response['data']);
        }
    }

    // ------------------------- Delete API --------

    async function DeleteStationery(deleteStatione) {
        const response = await con.DeleteEvent(deleteStatione);

        if (response['date'] === true) {
            let afterAdded = stationery.filter(item => item.id !== deleteStatione);
            setStationery(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }


    // ----------------- for file view --------------

    const openDocument = (url) => {
        window.open(url, '_blank');
    };


    const [searchTerm, setSearchTerm] = useState("");

    const searchedstationery = stationery.filter((c) => (c.title &&
        c.title.includes(searchTerm))
    );
    // const searchedstationery = stationery.filter((c) =>
    //     (c.date && c.date.includes(searchTerm)) ||
    //     (c.data && c.data.some((t) => t.title && t.title.toLowerCase().includes(searchTerm.toLowerCase())))
    // );

    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentother = searchedstationery.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);




    return (
        <React.Fragment>
            {userRoleId === '3' && staff_category_name === "Auditor" ? "" : <button type="button" className="btn btn-primary my-5" aria-hidden="true"
                data-toggle="modal"
                data-target="#addothers"
                onClick={() => handleButtonClick('Add Event', 'Submit')}
                style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
            ><span className="me-1" style={{ fontSize: "20px" }}>+</span>Add Event</button>}


            <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

                <input className="form-control searchBar mb-5" type="text"
                    placeholder="Global Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px", cursor: "pointer" }}>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Image URL</th>

                            <th>date</th>
                            {userRoleId === '1' && staff_category_name === "Auditor" ? "" : <th>Edit</th>}
                            {userRoleId === '1' && staff_category_name === "Auditor" ? "" : <th>Delete</th>}
                        </tr>
                    </thead>

                    <tbody>

                        {currentother.map((statione, index) => {

                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;

                            return (
                                <tr key={index}>
                                    <td>{continuousIndex}</td>
                                    <td>{statione.title}</td>
                                    <td>
                                        <a href={statione.image} target="_blank" rel="noopener noreferrer">
                                            {statione.image}
                                        </a>
                                    </td>
                                    <td>{statione.date}</td>
                                    {!((userRoleId === '3' && staff_category_name === "Auditor")) && (
                                        <>
                                            <td>
                                                <p className="p-2">
                                                    <RiEdit2Line
                                                        className="edit_icon"
                                                        data-toggle="modal"
                                                        data-target="#addothers"
                                                        onClick={() => handleForEditButtonClick('Edit Others', 'Update', statione)}
                                                    />
                                                </p>
                                            </td>
                                            <td>
                                                <p className="my-3">
                                                    <RiDeleteBin2Line
                                                        className="delete_icon"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => DeleteStationery(statione.id)}
                                                    />
                                                </p>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}

                        <hr />
                    </tbody>

                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={searchedstationery.length}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div>


            <section>
                <div className="container">


                    <div className="modal fade" id="addothers" role="dialog">
                        <div className="po_sc_in">

                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                    </button>

                                </div>
                                <div className="modal-body">
                                    <form onSubmit={submitStationary}>

                                        <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Title:</span>
                                                </label>
                                                <input name="title" type="text" className="form-control"
                                                    placeholder=" Name"
                                                    value={state.title}
                                                    // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'title' }); }}
                                                    onChange={(e) => {
                                                        var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                        if (sanitizedValue.length > 150) {
                                                            sanitizedValue = sanitizedValue.slice(0, 150);
                                                        }
                                                        const regex = /(.)\1{5,}/g;
                                                        sanitizedValue = sanitizedValue.replace(regex, '');
                                                        dispatch({ type: 'set_value', value: sanitizedValue, key: 'title' });
                                                    }}
                                                    required />
                                            </div>

                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Image: (pdf, jpg, png, svg)</span>
                                                </label>
                                                <input name="file" id="fileInput" type="file"
                                                    placeholder=""
                                                    // value={state.image}
                                                    // onChange={handleFileInputChange}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.files[0], key: 'image' }); }}
                                                />
                                            </div>
                                            {/* <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Image: (pdf, jpg, png, svg)</span>
                                                </label>
                                                <input
                                                    name="file"
                                                    id="fileInput"
                                                    type="file"
                                                    onChange={(e) => handleFileInputChange(e, dispatch)}
                                                />
                                            </div> */}

                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">date</span>
                                                </label>
                                                <input name="date" type="date" className="form-control"
                                                    placeholder="date"
                                                    value={state.date}
                                                    // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'date' }); }}
                                                    onChange={(e) => {
                                                        dispatch({ type: 'set_value', value: e.target.value, key: 'date' });
                                                    }}
                                                    required />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>{button}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

export default Event