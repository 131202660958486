import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import AuthRegister from 'layouts/auth/register';
import Patient from 'layouts/patients';
import PatientListShow from 'layouts/patients/patientlist';
import DoctorListShow from 'layouts/doctor/doctorlistshow';
import StaffListShow from 'layouts/staff/stafflistshow';
import InventoryListShow from 'layouts/inventory/inventorylistshow';
import SettingShow from 'layouts/setting/settingshow';
import AccountListShow from 'layouts/account/accountlistshow';
import PharmacyListShow from 'layouts/pharmacy/pharmacylistshow';
import DoctorDetial from 'views/doctor/doctordetial';
import MedicalInventory from 'views/patient/medicalinventory';
import PatientInvioce from 'views/patient/invoice';
import UserProvider from "./patient/exp";
import ForgetShow from 'layouts/auth/forgetshow';
import PatientDetailComponent from "views/patient/patientdetial";
import ConsiltantExDetial from 'views/accounts/accounttab.jsx/consultantexpedetial';
import Profile from 'views/auth/profile';
import PrescriptionDown from 'views/patient/prescription_download';
import PrescriptionInvoice from 'views/patient/prescription_invoice';
import DiagnosisSummary from 'views/patient/DiagnosisSummary'
import DiagnosisType from 'views/settings/TreatmentSetting/diagnosistype';
import SettingDiagnosisList from 'views/settings/TreatmentSetting/diagnosislist';
import Treatmentplan from 'views/settings/TreatmentSetting/treatmentplan';
import Treatmentprocedure from 'views/settings/TreatmentSetting/treatmentprocedure';
import Treatmentmethod from 'views/settings/TreatmentSetting/treatmentmethod';
import Diagnosis from 'views/patient/diagnosis';
import Payment from 'views/patient/payment';
import Prescription from 'views/patient/prescription';
import MedicalReport from 'views/patient/medicalreport';
import TreatmentPlanMap from 'views/patient/TreatmentPlanMap';
import PatientEnquiry from 'views/patient/enquiry';
import RegisterVerifyOTP from 'views/auth/Registerverify';
import AddPatientMember from 'views/patient/addmenber';
import Event from 'views/event/event';


const App = () => {

  const user_role_id = localStorage.getItem('role_id');
  console.log(user_role_id)

  function checkTokenExpiry() {
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');
    if (!tokenTimestamp) return;

    const now = Date.now();
    const expiryTime = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    // const expiryTime = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (now - tokenTimestamp > expiryTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenTimestamp');
      localStorage.removeItem('role_id');
      localStorage.removeItem('userdata');
      localStorage.removeItem('members');
      console.log("Token has expired and has been removed.");

      // Redirect to the login page
      window.location.href = 'auth/';
    }
  }
  useEffect(() => {
    checkTokenExpiry();

    const interval = setInterval(checkTokenExpiry, 60 * 60 * 1000); // Check every hour
    // const interval = setInterval(checkTokenExpiry, 1 * 60 * 1000); // Check every minute

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (

    <UserProvider>
      <div>
        {(user_role_id === null || user_role_id === undefined) ?
          (
            <Routes>
              <Route path="auth/*" element={<AuthLayout />} />
              <Route path="auth/register" element={<AuthRegister />} />
              <Route path="forget" element={<ForgetShow />} />
              <Route path="/verify" element={<RegisterVerifyOTP />} />
              <Route path="/" element={<Navigate to="/auth" replace />} />
            </Routes>
          ) :
          (
            <Routes>

              {/* ----------------- for a admin ------------ */}

              {/* <Route path="auth/*" element={<AuthLayout />} /> */}
              <Route path="auth/register" element={<AuthRegister />} />
              <Route path="patient-detial" element={<Patient />} />
              <Route path="patient/patientslist" element={<PatientListShow />} />
              <Route path="doctor-lists" element={<DoctorListShow />} />
              <Route path="staff-lists" element={<StaffListShow />} />
              <Route path="inventory-lists" element={<InventoryListShow />} />
              <Route path="Doctor-Detial" element={<DoctorDetial />} />
              <Route path="/*" element={<AdminLayout />} />
              <Route path="medicalinventory" element={<MedicalInventory />} />
              <Route path="/prescription-Invoice" element={<PrescriptionInvoice />} />
              <Route path="/Patient-Invoice" element={<PatientInvioce />} />
              <Route path="/Patient-presciption" element={<PrescriptionDown />} />
              <Route path="Patientdetial" element={<PatientDetailComponent />} />
              <Route path="/consultantdetial" element={<ConsiltantExDetial />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/diagnosistype" element={<DiagnosisType />} />
              <Route path="/diagnosislist" element={<SettingDiagnosisList />} />
              <Route path="/treatmentplan" element={<Treatmentplan />} />
              <Route path="/treatmentprocedure" element={<Treatmentprocedure />} />
              <Route path="/treatmentmethod" element={<Treatmentmethod />} />
              <Route path="/summary" element={<DiagnosisSummary />} />
              <Route path="/diagnosis" element={<Diagnosis />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/prescription" element={<Prescription />} />
              <Route path="/medicalreport" element={<MedicalReport />} />
              <Route path="/treatmentplanmap" element={<TreatmentPlanMap />} />
              <Route path="/patientenquiry" element={<PatientEnquiry />} />
              <Route path="/patientmember" element={<AddPatientMember />} />
              <Route path="/event" element={<Event />} />
            </Routes>
          )
        }
      </div>
    </UserProvider>
  );
};

export default App;
