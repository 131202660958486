import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import * as con from '../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { RiEdit2Line, RiMailAddLine } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaEllipsis } from "react-icons/fa6";




function reducer(state, action) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    return {
      id: state.id,
      quantity: state.quantity,
      category_id: state.category_id,
      prescription_subcategory_id: state.prescription_subcategory_id,
      morning: state.morning,
      afternoon: state.afternoon,
      evening: state.evening,
      night: state.night,
      before_food: state.before_food,
      after_food: state.after_food,
      duration: state.duration,
      patient_id: state.patient_id,
      prescription_id: state.prescription_id,
      total_amt: state.total_amt,
      paid_amt: state.paid_amt,
      status: state.status
    };
  }

}

function reducerpay(statepay, action) {
  if (action.type === 'set_valuepay') {
    statepay[action.key] = action.value;
    // statepay.patient_id = 
    return {
      id: statepay.id,
      prescription_id: statepay.prescription_id,
      total_amt: statepay.total_amt,
      date: statepay.date,
      paid_amt: statepay.paid_amt,
      status: statepay.status,
      patient_id: statepay.patient_id
    }
  }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <ul className="pagination-list">
        {pageNumbers.map((number) => (
          <li key={number} className="pagination-item">
            <a

              onClick={() => paginate(number)}
              className={`pagination-link ${currentPage === number ? 'active' : ''}`}
              style={{ cursor: "pointer" }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Prescription = (props) => {

  // const location = useLocation();
  // const userData = location.state;
  const userRoleId = localStorage.getItem('role_id');
  const navigate = useNavigate();
  const patient = props.patient_id
  console.log(props.patient_id)
  const [prescriptionList, setPrescriptionList] = useState([])

  console.log(patient)




  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentprescription = prescriptionList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);






  const [state, dispatch] = useReducer(reducer, {
    id: '', quantity: '', category_id: '', prescription_subcategory_id: '', morning: '', afternoon: '',
    evening: '', night: '', before_food: '', after_food: '', duration: '', patient_id: (props.patient_id), total_amt: '', paid_amt: '', status: '', prescription_id: '',
  })
  const [statepay, dispatchpay] = useReducer(reducerpay, {
    id: '', patient_id: '', total_amt: '', date: '', paid_amt: '', status: '', prescription_id: '',
  })

  const submitAddPrescription = (event) => {
    event.preventDefault();
    if (state.id != '') {
      addPrescription(state, 'edit');
    } else {
      addPrescription(state, 'add');
    }
    console.log(props.patient_id)
  }


  async function addPrescription(Prescription, type) {
    if (type == 'add') {
      const response = await con.AddPrescription((JSON.stringify(Prescription)));
      console.log(response)
      if (response['status'] === true) {
        // let afterAdded = [...prescriptionList];
        // afterAdded.push(response['data']);
        // setPrescriptionList(afterAdded);
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          window.location.reload();
        }, 800);

      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });

      }
    } else {
      const response = await con.UpdatePatientPrescriptAPI((JSON.stringify(Prescription)));
      console.log(response)
      if (response['status'] === true) {
        // let afterAdded = prescriptionList.map(item => {
        //   if (item.id === state.id) {
        //     return response.data;
        //   }
        //   return item;
        // });

        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'quantity' })
        dispatch({ type: 'set_value', value: '', key: 'category_id' })
        dispatch({ type: 'set_value', value: '', key: 'prescription_subcategory_id' })
        dispatch({ type: 'set_value', value: '', key: 'morning' })
        dispatch({ type: 'set_value', value: '', key: 'afternoon' })
        dispatch({ type: 'set_value', value: '', key: 'evening' })
        dispatch({ type: 'set_value', value: '', key: 'night' })
        dispatch({ type: 'set_value', value: '', key: 'before_food' })
        dispatch({ type: 'set_value', value: '', key: 'after_food' })
        dispatch({ type: 'set_value', value: '', key: 'duration' })
        dispatch({ type: 'set_value', value: '', key: 'patient_id' })

        setPrescriptionList(afterAdded);
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });

      }
    }
  }
  // -----------------------------  add method for dropdown

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleButtonClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  //  // ---------------- for edit function -------

  function handleForEditButtonClick(subCat) {
    console.log(subCat)
    setDropdownOpen(true);
    dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
    dispatch({ type: 'set_value', value: subCat.quantity, key: 'quantity' })
    dispatch({ type: 'set_value', value: subCat.category_id, key: 'category_id' })
    dispatch({ type: 'set_value', value: subCat.prescription_subcategory_id, key: 'prescription_subcategory_id' })
    dispatch({ type: 'set_value', value: subCat.morning, key: 'morning' })
    dispatch({ type: 'set_value', value: subCat.afternoon, key: 'afternoon' })
    dispatch({ type: 'set_value', value: subCat.evening, key: 'evening' })
    dispatch({ type: 'set_value', value: subCat.night, key: 'night' })
    dispatch({ type: 'set_value', value: subCat.before_food, key: 'before_food' })
    dispatch({ type: 'set_value', value: subCat.after_food, key: 'after_food' })
    dispatch({ type: 'set_value', value: subCat.duration, key: 'duration' })
    dispatch({ type: 'set_value', value: subCat.patient_id, key: 'patient_id' })
  };
  // ------------------------------- prescription   list API -----------




  useEffect(() => {
    getPrescriptionLists();
  }, [])

  async function getPrescriptionLists() {
    const response = await con.PrescriptionListsAPI({
      "patient_id": state.patient_id
    });
    console.log(response)
    if (response['status'] === true) {

      console.log(response['data'])
      console.log("-----------------below line-----------------")
      setPrescriptionList(response['data'].reverse());
    }
  }



  // ------------------prescription drop down list ------------
  const [presMenuList, setPresMenuList] = React.useState();

  useEffect(() => {
    getpresmenutype()
  }, [])

  async function getpresmenutype() {
    const response = await con.MedicalInventoryListsAPI();
    console.log(response)
    if (response['status'] === true) {
      setPresMenuList(response['data'])
      dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'prescription_subcategory_id' });
    }
  }


  // ----------------------- dropdown 1 list --------------

  const [PharmaccatagoList, setPharamacyCatagoList] = React.useState();


  useEffect(() => {
    getPharmCatTyp();
    getmedicalInventoryLists()
  }, [])

  async function getPharmCatTyp() {
    const response = await con.PharamaCatagAPI();
    console.log(response['data'])
    if (response['status'] === true) {
      setPharamacyCatagoList(response['data']);
      dispatch({ type: 'set_value', value: response['data']['id'], key: 'category_id' })
    }
  }

  // ----------------------- dropdown 2 list --------------

  const [medicalInventory, setMedicalInventory] = React.useState();



  async function getmedicalInventoryLists(id) {
    console.log(id)
    const response = await con.MedicalInventoryListsAPI();
    console.log(response['data'])
    if (response['status'] === true) {
      const filteredId = response.data.filter((item) => item.category_id !== null && item?.category_id.toString() === id);

      // setMedicalInventory(response['data'])
      console.log(filteredId)
      console.log(response['data'])
      setMedicalInventory(filteredId)
      // dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'prescription_subcategory_id' });
    }
  }


  // ------------------------- Delete API --------

  async function getPatiePrescriptionDelete(deletePatienPresc) {
    const response = await con.PatientPrescription(deletePatienPresc);
    console.log(response)
    if (response['status'] === true) {
      let afterAdded = prescriptionList.filter(item => item.id !== deletePatienPresc);
      setPrescriptionList(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      window.location.reload();
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }

  console.log(medicalInventory)

  // const fun = (cat) => {
  //   // const name = PharmaccatagoList.map((list, index) => list.name)
  //   getmedicalInventoryLists(cat);
  // }
  function fun(id) {
    getmedicalInventoryLists(id)
  }


  // ---------------- action dropdown ----
  const [isOpen, setIsOpen] = useState(false);


  const [presIds, setPresIds] = useState([]);

  function toggleDropdown(ids) {
    let idsValue = ids.prescription.map((i) => i.id);
    dispatchpay({ type: 'set_valuepay', value: idsValue, key: 'prescription_id' });
    dispatchpay({ type: 'set_valuepay', value: ids.sum, key: 'total_amt' });
    dispatchpay({ type: 'set_valuepay', value: ids.date, key: 'date' });
    dispatchpay({ type: 'set_valuepay', value: ids.prescription[0].patient_id, key: 'patient_id' });
    setPresIds(idsValue);
    console.log(ids);
    console.log(ids.prescription[0].patient_id);
    setIsOpen(!isOpen);
  }


  const [editmode, setEditmode] = useState(false);

  // ----------- Change Table Heading and Button Dynamically -------------------

  const [heading, setHeading] = useState('Default Heading');
  const [button, setButton] = useState('Default Heading');
  const [addPharm, setaddPharm] = useState(false)
  const [isDropdownOpenforpay, setDropdownOpenpay] = useState(false);
  const handleForAddButtonClickpay = (newHeading, newButton) => {
    setHeading(newHeading);
    setButton(newButton)
    setEditmode(false)
    setDropdownOpenpay(!isDropdownOpenforpay);
    setaddPharm(!addPharm);

    // dispatch({ type: 'set_value', value: '', key: 'id' })
    // dispatch({ type: 'set_value', value: '', key: 'sum' })

  };

  // ---------------- for edit function -------

  function handleForEditButtonClickpay(newHeading, newButton, plist) {
    setHeading(newHeading);
    setButton(newButton)
    setDropdownOpenpay(true);
    setEditmode(true)

    dispatchpay({ type: 'set_valuepay', value: plist.prescription[0].prescription_payment_id, key: 'id' })
    dispatchpay({ type: 'set_valuepay', value: plist.prescription[0].patient_id, key: 'patient_id' })
    dispatchpay({ type: 'set_valuepay', value: plist.prescription[0].paid_amt, key: 'paid_amt' })
    dispatchpay({ type: 'set_valuepay', value: plist.prescription[0].total_amt, key: 'total_amt' })
    dispatchpay({ type: 'set_valuepay', value: plist.prescription[0].status, key: 'status' })
  };


  const submitPrescriptioPay = (event) => {
    event.preventDefault();
    if (statepay.id !== '') {

      prescripay(statepay, 'edit');
    } else {
      prescripay(statepay, 'add');
    }
  }


  async function prescripay(prescripayData, type) {
    if (type === 'add') {
      const response = await con.StorePrescriptionPayAPI((JSON.stringify(prescripayData)));
      console.log(response);
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });

        setTimeout(() => {
          window.location.reload();
        }, 500);

      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        })
      }
    } else {
      const response = await con.UpdatePrescriptionPayAPI((JSON.stringify(prescripayData)));
      console.log(response)
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    }
  }


  return (
    <React.Fragment>
      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
        (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: "10px" }}>
            < button type="submit"
              className="btn btn-primary mb-5"
              style={{ width: "auto" }}
              onClick={handleButtonClick}
            >+ Add Prescription
            </button>
          </div>
        ) : null}
      {isDropdownOpen && (

        <div className="container-fluid prescard_outer">

          <form className="prescriptionForm" onSubmit={submitAddPrescription}>

            <div className="presrow" >

              <div className="presrow-6">
                <label>Category Name</label>
                <div>
                  {(PharmaccatagoList != null) ?
                    <select
                      className="form-select"
                      style={{ fontSize: 14, height: 44, width: "100%" }}
                      required
                      value={state.category_id}

                      onChange={(e) => {

                        dispatch({ type: 'set_value', value: e.target.value, key: 'category_id' });
                        fun(e.target.value);
                        console.log(e.target.value)
                      }}
                    >
                      <option>Select Category.....</option>
                      {PharmaccatagoList.map((list, index) =>
                        <option value={list['id']}>{list['name']}</option>
                      )}

                    </select>
                    : null}
                </div>

              </div>

              <div className="presrow-1">
                <label>Prescription Name</label>
                <div style={{ marginTop: "1rem" }}>
                  <select
                    className="form-select"
                    style={{
                      fontSize: 14,
                      height: 44,
                      width: "100%"
                    }}
                    required
                    value={state.prescription_subcategory_id}
                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'prescription_subcategory_id' }); }}
                  >
                    <option>Select Prescription.....</option>
                    {(medicalInventory != null) ?
                      medicalInventory.map((list, index) =>
                        <option value={list['id']}>{list['sub_name']}</option>
                      )
                      : null}
                  </select>

                </div>

              </div>

              <div className="presrow-6">
                <label>Quantity</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: '15px', width: '100%', height: 44 }}
                    value={state.quantity}
                    // minLength={1} maxLength={5}
                    required
                    // onChange={(e) => {
                    //   const enteredValue = e.target.value;
                    //   if (enteredValue >= 0) {
                    //     dispatch({ type: 'set_value', value: enteredValue, key: 'quantity' });
                    //   }
                    // }}
                    // onChange={(e) => {
                    //   var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                    //   dispatch({ type: 'set_value', value: sanitizedValue, key: 'quantity' });
                    // }}
                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'quantity' }); }}
                  />
                </div>
              </div>

              <div className="presrow-6">
                <label>Duration</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: '15px', width: '100%', height: 44 }}
                    // minLength={1} maxLength={3}
                    required
                    value={state.duration}
                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'duration' }); }}
                  // onChange={(e) => {
                  //   const enteredValue = e.target.value;
                  //   if (enteredValue >= 0) {
                  //     dispatch({ type: 'set_value', value: enteredValue, key: 'duration' });
                  //   }
                  // }}
                  // onChange={(e) => {
                  //   var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                  //   dispatch({ type: 'set_value', value: sanitizedValue, key: 'duration' });
                  // }}
                  />
                </div>
              </div>


              <div className="presrow-1">
                <label style={{ marginLeft: '90px' }}>Intake</label>
                <div>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <label className="mt10">After Food</label>
                    <input
                      type="checkbox"
                      checked={state.after_food === 'After Food'}
                      onChange={(e) => {
                        dispatch({
                          type: 'set_value',
                          value: e.target.checked ? 'After Food' : '',
                          key: 'after_food'
                        });

                        if (e.target.checked) {
                          dispatch({
                            type: 'set_value',
                            value: '',
                            key: 'before_food'
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <label className="mt10">Before Food</label>
                      <span style={{ marginRight: '3px', marginTop: '8px' }}>
                        <input
                          type="checkbox"
                          checked={state.before_food === 'Before Food'}
                          onChange={(e) => {
                            dispatch({
                              type: 'set_value',
                              value: e.target.checked ? 'Before Food' : '',
                              key: 'before_food'
                            });

                            if (e.target.checked) {
                              dispatch({
                                type: 'set_value',
                                value: '',
                                key: 'after_food'
                              });
                            }
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <label>Morning</label>
              <span style={{ marginRight: '13px', marginLeft: "6px" }}>
                <input
                  type="checkbox"
                  className="form-check-input"

                  checked={state.morning}
                  onChange={() => {
                    dispatch({ type: 'set_value', value: !state.morning, key: 'morning' });
                  }}
                />
              </span>

              <label>Afternoon</label>
              <span style={{ marginRight: '13px', marginLeft: "6px" }}>
                <input
                  type="checkbox"
                  className="form-check-input"

                  checked={state.afternoon}
                  onChange={() => {
                    dispatch({ type: 'set_value', value: !state.afternoon, key: 'afternoon' });
                  }}
                />
              </span>

              <label>Evening</label>
              <span style={{ marginRight: '13px', marginLeft: "6px" }}>
                <input
                  type="checkbox"
                  className="form-check-input"

                  checked={state.evening}
                  onChange={() => {
                    dispatch({ type: 'set_value', value: !state.evening, key: 'evening' });
                  }}
                />
              </span>

              <label>Night</label>
              <span style={{ marginRight: '13px', marginLeft: "6px" }}>
                <input
                  type="checkbox"
                  className="form-check-input"

                  checked={state.night}
                  onChange={() => {
                    dispatch({ type: 'set_value', value: !state.night, key: 'night' });
                  }}
                />
              </span>
            </div>


            <button className="btn btn-primary presbtn" >
              Submit
            </button>
          </form>

        </div>

      )}
       {/* <Tables> </Tables> */}
      {/* <div className="tablescroll"> */}
       
        {/* <table className="bordered-table tablescroll">
          <thead className="tablehead">
            <tr>
              <th>S.No</th>
              <th>Date</th>
              
              <th>Prescription Name</th>
              <th>Quantity</th>
              
              <th>Duration</th>
              
              <th>M - A - E - N</th>
              <th>Intake</th>
              <th>Total</th>
              
              <th>Due</th>
              <th>Status</th>

              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '3') ?
                (
                  <th>Pay</th>
                ) : null}
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
                (
                  <th>Edit</th>
                ) : null}
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
                (
                  <th>Delete</th>
                ) : null}

              <th>View Prescription</th>
              <th>View Invoice</th>
            </tr>
          </thead>
          <tbody>

            
            {currentprescription.length > 0 ? (
              currentprescription.map((plist, index) => {
                const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                console.log(plist)
                return (

                  <tr key={index}>
                    <td>{continuousIndex}</td>
                    <td>{plist.date}</td>
                    
                    <td>
                      {plist.prescription.map((nestedItem) => (
                        <p className="my-3">  {nestedItem.sub_name}</p>
                      ))}
                    </td>
                    <td>
                      {plist.prescription.map((nestedItem) => (
                        <p className="my-3">  {nestedItem.quantity}</p>
                      ))}
                    </td>
                    <td>
                      {plist.prescription.map((nestedItem) => (
                        <p className="my-3">  {nestedItem.duration}</p>
                      ))}
                    </td>
                    <td>
                      {plist.prescription.map((nestedItem) => (
                        <p className="my-3">
                          <span>  {nestedItem.morning ? '1' : '0'} - </span>
                          <span className="me-2">  {nestedItem.afternoon ? '1' : '0'} - </span>
                          <span className="me-2">  {nestedItem.evening ? '1' : '0'} - </span>
                          <span className="me-2">  {nestedItem.night ? '1' : '0'}</span>
                        </p>
                      ))}
                    </td>
                    <td>
                      {plist.prescription.map((nestedItem) => (

                        <p className="my-3">
                          <span>{nestedItem.after_food}</span>
                          <span>{nestedItem.before_food}</span>
                        </p>

                      ))}
                    </td>
                    <td>{plist.sum}</td>
                    
                    {plist.prescription[0].balance < 0 ? null : (
                      <td>
                        <p className="my-3"><span>{plist.prescription[0].balance !== null ? plist.prescription[0].balance : '-'}</span></p>
                      </td>
                    )}

                    <td><p className="my-3"><span>{plist.prescription[0].balance !== null ? plist.prescription[0].status : '-'}</span></p></td>
                    



                    {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '3') ?
                      (
                        
                        <td>
                          <div className="dropdown">
                            <FaEllipsis onClick={() => toggleDropdown(plist)} className="ellipsisicon" />
                            {(isOpen) && (
                              <div className="dropdown-content" >
                                {plist.prescription[0].balance >= -1 ? (
                                  <div className="lis" aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#priscriptionpayment"
                                    onClick={() => handleForAddButtonClickpay('Add Prescription Payment')}
                                    style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                      <RiMailAddLine className="edit_icon me-2" /><span>Add Pay</span>
                                    </span>
                                  </div>
                                ) : null}

                                
                                <div className="lis" aria-hidden="true"
                                  data-toggle="modal"
                                  data-target="#priscriptionpayment"
                                  onClick={() => handleForEditButtonClickpay('Edit Prescription Payment', 'Update', plist)}
                                  style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                                  <span style={{ display: 'flex', justifyContent: 'center' }}>
                                    <RiEdit2Line className="edit_icon me-2" /><span >Edit Pay</span></span>
                                </div>



                              </div>
                            )}
                          </div>
                        </td>
                      ) : null}
                    {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
                      (
                        <td>
                          {plist.prescription.map((nestedItem, index) => (

                            <p className="my-3"> <RiEdit2Line className="edit_icon" onClick={() => handleForEditButtonClick(nestedItem)} /></p>

                          ))}
                        </td>
                      ) : null}
                    {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
                      (
                        <td>
                          {plist.prescription.map((nestedItem, index) => (

                            <p className="my-3">   <RiDeleteBin2Line
                              className="delete_icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => getPatiePrescriptionDelete(nestedItem.id)}
                            /></p>

                          ))}
                        </td>
                      ) : null}


                   
                    <td>
                     
                      <button className='btn btn-primary' onClick={() => {
                        navigate("/Patient-presciption", {
                          state: {
                            plist: plist,
                            sub_name: plist.sub_name,
                            quantity: plist.quantity,
                            // price: plist.price,
                            duration: plist.duration,
                            // total_amt: plist.total_amt,
                            morning: plist.morning,
                            afternoon: plist.afternoon,
                            evening: plist.evening,
                            night: plist.night,
                            after_food: plist.after_food,
                            before_food: plist.before_food,

                            patient: props.pData,
                          },
                        },);
                      }}>
                        View Prescription
                      </button>
                    </td>


                    <td>
                     
                      <button className='btn btn-primary' onClick={() => {
                        navigate("/prescription-Invoice", {
                          state: {
                            plist: plist,
                            sub_name: plist.sub_name,
                            quantity: plist.quantity,
                            // price: plist.price,
                            duration: plist.duration,
                            // total_amt: plist.total_amt,
                            morning: plist.morning,
                            afternoon: plist.afternoon,
                            evening: plist.evening,
                            night: plist.night,
                            after_food: plist.after_food,
                            before_food: plist.before_food,

                            patient: props.pData,
                          },
                        },);
                      }}>
                        View Invoice
                      </button>
                    </td>
                  </tr>
                  // ))}
                )
              })
            ) : (
              <tr>
                <td colSpan="10">There is no prescription.</td>
              </tr>
            )}

          </tbody>
        </table> */}
          <div className='tabtablediagno' style={{ width: "100%", height: "auto", backgroundColor: "white", marginTop: "30px", marginBottom: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
        <table className="bordered-table prescription-tablescroll">
  <thead className="tablehead">
    <tr>
      <th>S.No</th>
      <th>Date</th>
      <th>Prescription Name</th>
      <th>Quantity</th>
      <th>Duration</th>
      <th>M - A - E - N</th>
      <th>Intake</th>
      <th>Total</th>
      <th>Due</th>
      <th>Status</th>
      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '3') ?
        <th>Pay</th> : null}
      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
        <th>Edit</th> : null}
      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
        <th>Delete</th> : null}
      <th>View Prescription</th>
      <th>View Invoice</th>
    </tr>
  </thead>
  <tbody>
    {currentprescription.length > 0 ? (
      currentprescription.map((plist, index) => {
        const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
        return (
          <tr key={index}>
            <td>{continuousIndex}</td>
            <td>{plist.date}</td>
            <td>
              {plist.prescription.map((nestedItem) => (
                <p className="my-3">  {nestedItem.sub_name}</p>
              ))}
            </td>
            <td>
              {plist.prescription.map((nestedItem) => (
                <p className="my-3">  {nestedItem.quantity}</p>
              ))}
            </td>
            <td>
              {plist.prescription.map((nestedItem) => (
                <p className="my-3">  {nestedItem.duration}</p>
              ))}
            </td>
            {/* <td>
              {plist.prescription.map((nestedItem) => (
                <p >
                  <span>  {nestedItem.morning ? '1' : '0'} - </span>
                  <span className="me-2">  {nestedItem.afternoon ? '1' : '0'} - </span>
                  <span className="me-2">  {nestedItem.evening ? '1' : '0'} - </span>
                  <span className="me-2">  {nestedItem.night ? '1' : '0'}</span>
                </p>
              ))}
            </td> */}
            <td>
  {plist.prescription.map((nestedItem, index) => (
    <ul key={index} className="list-unstyled d-flex">
      <li className="me-2"> {nestedItem.morning ? '1' : '0'}</li>
      <li className="me-2"> {nestedItem.afternoon ? '1' : '0'}</li>
      <li className="me-2"> {nestedItem.evening ? '1' : '0'} </li>
      <li> {nestedItem.night ? '1' : '0'}</li>
    </ul>
  ))}
</td>
            <td>
              {plist.prescription.map((nestedItem) => (
                <p className="my-3">
                  <span>{nestedItem.after_food}</span>
                  <span>{nestedItem.before_food}</span>
                </p>
              ))}
            </td>
            <td>{plist.sum}</td>
            {plist.prescription[0].balance < 0 ? <td> 0</td>: (
              <td>
                <p className="my-3"><span>{plist.prescription[0].balance !== null ? plist.prescription[0].balance : '-'}</span></p>
              </td>
            )}
            <td><p className="my-3"><span>{plist.prescription[0].balance !== null ? plist.prescription[0].status : '-'}</span></p></td>
            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '3') ?
              <td>
                <div className="dropdown">
                  <FaEllipsis onClick={() => toggleDropdown(plist)} className="ellipsisicon" />
                  {(isOpen) && (
                    <div className="dropdown-content">
                      {plist.prescription[0].balance >= -1 ? (
                        <div className="lis" aria-hidden="true" data-toggle="modal" data-target="#priscriptionpayment" onClick={() => handleForAddButtonClickpay('Add Prescription Payment')} style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                          <span style={{ display: 'flex', justifyContent: 'center' }}>
                            <RiMailAddLine className="edit_icon me-2" /><span>Add Pay</span>
                          </span>
                        </div>
                      ) : null}
                      <div className="lis" aria-hidden="true" data-toggle="modal" data-target="#priscriptionpayment" onClick={() => handleForEditButtonClickpay('Edit Prescription Payment', 'Update', plist)} style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                          <RiEdit2Line className="edit_icon me-2" /><span>Edit Pay</span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </td> : null}
            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
              <td>
                {plist.prescription.map((nestedItem, index) => (
                  <p className="my-3"> <RiEdit2Line className="edit_icon" onClick={() => handleForEditButtonClick(nestedItem)} /></p>
                ))}
              </td> : null}
            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
              <td>
                {plist.prescription.map((nestedItem, index) => (
                  <p className="my-3">   <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }} onClick={() => getPatiePrescriptionDelete(nestedItem.id)} /></p>
                ))}
              </td> : null}
            <td>
              <button className='btn btn-primary' onClick={() => {
                navigate("/Patient-presciption", {
                  state: {
                    plist: plist,
                    sub_name: plist.sub_name,
                    quantity: plist.quantity,
                    duration: plist.duration,
                    morning: plist.morning,
                    afternoon: plist.afternoon,
                    evening: plist.evening,
                    night: plist.night,
                    after_food: plist.after_food,
                    before_food: plist.before_food,
                    patient: props.pData,
                  },
                });
              }}>View Prescription</button>
            </td>
            <td>
              <button className='btn btn-primary' onClick={() => {
                navigate("/prescription-Invoice", {
                  state: {
                    plist: plist,
                    sub_name: plist.sub_name,
                    quantity: plist.quantity,
                    duration: plist.duration,
                    morning: plist.morning,
                    afternoon: plist.afternoon,
                    evening: plist.evening,
                    night: plist.night,
                    after_food: plist.after_food,
                    before_food: plist.before_food,
                    patient: props.pData,
                  },
                });
              }}>View Invoice</button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="12">There is no prescription.</td>
      </tr>
    )}
  </tbody>
</table>
</div>

      {/* </div> */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={prescriptionList.length}
        currentPage={currentPage}
        paginate={paginate}
      />



      {/* --------------------------------- payment edit popup ---------------- */}

      <section>
        <div className="container">
          <div className="modal fade" id="priscriptionpayment" role="dialog">
            <div className="po_sc_in">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                  <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <form onSubmit={submitPrescriptioPay}>
                    <div className="row">
                      {/* <div className="col-md-3">

                      </div> */}
                      <div className="col-md-6 mb-sm-7 mb-4">
                        <label className="form-label">
                          <span className="required">Prescription Payment</span>
                        </label>
                        <input name="paid_amt" type="number" className="form-control"
                          placeholder="Payment"
                          value={statepay.paid_amt}
                          onChange={(e) => { dispatchpay({ type: 'set_valuepay', value: e.target.value, key: 'paid_amt' }); }}
                          required />
                      </div>
                      <div className="col-md-6 mb-sm-7 mb-4">
                        <label className="form-label">
                          <span className="required">Status</span>
                        </label>
                        <input name="status" type="text" className="form-control"
                          placeholder="Status"
                          value={statepay.status}
                          onChange={(e) => { dispatchpay({ type: 'set_valuepay', value: e.target.value, key: 'status' }); }}
                          required />
                      </div>
                      {/* <div className="col-md-3">

                      </div> */}

                    </div>
                    <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                  </form>
                </div>
                <div className="modal-footer">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </React.Fragment >
  )
}

export default Prescription